.slide-transform {
  -webkit-transition-property: top, bottom, width, background-image, margin, left;
  transition-property: top, bottom, width, background-image, margin, left;
  -webkit-transition-duration: .2s, .2s, .25s, .3s;
  transition-duration: .2s, .2s, .25s, .3s;
  -webkit-transition-timing-function: linear, linear, ease, linear;
  transition-timing-function: linear, linear, ease, linear;
  -webkit-overflow-scrolling: touch;
  transition-delay: .25s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .15s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.rtl {
  direction: rtl;
}

 ::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 8px;
}

 ::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 1px solid #fff;
}

 ::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(13deg, #c7ceff 14%, #f9d4ff 64%);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-button {
  display:none;
}

#stock-table{
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
    height: 22px !important;
  }
  
   ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 1px solid #fff;
  }
  
   ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(13deg, #c7ceff 14%, #f9d4ff 64%);
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-button {
    display:none;
  }
}