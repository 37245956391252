





































































































































































































































































































@import "@/styles/basics/globals.scss";
#crear-notificacion{
  width: 100%;
  height: 100%;
}
#crear-notificacion p, #crear-notificacion span{
  color: var(--color-text-primary);
}
.form-crear-notificacion {
  background-color: var(--color-background) !important;
}
.form-crear-notificacion-container{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-crear-notificacion{
  background: white;
  height: 90%;
  width: 50%;
  align-self: center;
}
.datepicker-input{
  text-align:  center;
  font-size: 11px;
  margin-top: 10px;
  width:10px;
  height: 38px;
  border-radius: 5px;
  border-color: #aaadb7;
  margin-left: 8px;
}
.form-rols{
    padding: 10px 0 10px 0;
}
.form-users{
    padding: 10px 0 10px 0;
}
.form-date{
    padding: 10px 0 10px 0;
}
.form-title{
    padding: 10px 0 10px 0;
}
.form-body{
    padding: 10px 0 10px 0;
}
.title-section{
    font-size: 20px;
    font-weight: bold;
}
.value-input-hours{
  width: 10%;
}
