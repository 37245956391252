@use "@material/button";


.dark {
  --mdc-theme-primary: #00add8;
  --mdc-theme-secondary: #00303D;
  background-color: #0F0F1A;  
  
  #app{
    background-color: #0F0F1A;
  }
  
  .pricing-title{
    color: white;
  }
  p {
    color: white;
  }
  span {
    color: white;
  }
  hr {
    color: white;
  }

  .material-icons-outlined {
    color:white;
  }

  .favorite.mdc-list-item__meta.material-icons{
    color:white;
  }

  .card-travel.mdc-list-item__meta.material-icons{
    color:white;
  }

  .alarm.push.mdc-list-item__meta.material-icons{
    color: white;
  }

  #content {
    background-color: #0F0F1A;
  }

  #sidebar-content {
    background-color: #0F0F1A;
  }

  //Stockchart-info component
  #stock-info{
    background-color: #0F0F1A;

    p{
      color: lightgrey;
    }

    span{
      color: white;
    }

    hr{
      background-color: white;
      margin: 15px auto 15px auto;
    }

    .text-info-ticker{
      text-align: center;
      background-color: #F3F4F7;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 24px;
      width: 130px;
      padding: 7px 20px;
      color: black;
    }

    .text-bold{
      font-weight: bold;
      color: black;
    }  
  }

  //stockchart-simulator component
  #simulator{
    background-color: #0F0F1A;

    p{
      color: white;
    }

    span{
      color:white;
    }

    .text-info-ticker{
      text-align: center;
      background-color: #F3F4F7;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 16px;
      padding: 7px 25px;
      color: black;
    
      span {
        font-size: 18px;
      }
    }

    .text-bold{
      font-weight: bold;
      color: black;
    }

    .simulator-chips-box{
      background-color: white; 
      span{
        color: black;
      }
    }

    .mdc-data-table__table{
      border: 1px solid white;
      background-color: black;

      .mdc-data-table__header-cell{
        background-color: black;
        color: white;
      }
    }
  }
  .mdc-text-field.mdc-text-field--outlined.input-empresa-ticket.mdc-text-field--disabled{
    background-color: rgb(25, 25, 25);
    border-bottom: 1px solid white;
    
  }
  .mdc-text-field.mdc-text-field--outlined.input-empresa-ticket-stoploss.mdc-text-field--disabled{
    background-color: rgb(25, 25, 25);
    border-bottom: 1px solid white;
  }
  .mdc-text-field.mdc-text-field--outlined.input_empresa_ticket-oco.mdc-text-field--disabled{
    background-color: rgb(25, 25, 25);
    border-bottom: 1px solid white;
  }

  //stockchart-description component
  #description{
    background-color: #0F0F1A;
  }

  //stockchart-error component
  #stock-error{
    background-color: #0F0F1A;

    p{
      color: black;
    }

    span{
      color: black;
    }

    .select_material_style.dynamic-stockchart-select-error-report.form-select{
      background-color: white;
    }

    .helper-text-error.mdc-text-field-helper-text.mdc-text-field-helper-text--persistent{
      color: white;
    }
  }

  //stockchart-menu component
  #stockchart-menu {
    background-color: #0F0F1A;
  }

  //stockchart-menu-secundario component
  #stockchart-menu-secundario{
    background-color: #0F0F1A;
  }
  //lista de ticekrs
  #my-list {
    background-color: #0F0F1A;
  }

  //stockchart-comments component
  #stock-comments{
    p {
      color:white;
    }
    span{
      color:white;
    }

    .text-info-ticker{
      text-align: center;
      background-color: #F3F4F7;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 24px;
      width: 130px;
      padding: 7px 20px;
      color: black;
    }

    .text-bold{
      font-weight: bold;
      color: black;
    }

    .material-icons.comment-icon-user{
      color: white;
    }

    .text-bold-points{
      font-weight: bold;
      color: white;
    }
  }

  //portfolio component
  #portfolio{
    .mdc-data-table__header-cell.mdc-data-table__header-cell--with-sort{
      background-color: black;
      color: white;
    }
    .mdc-data-table__header-cell{
      background-color: black;
      color: white;
    }

    .mdc-icon-button.material-icons.mdc-data-table__sort-icon-button{
      color: white;
    }
  }

  //pricing component
  #pricing-component{
    background-color: #0F0F1A;

    h3{
      color: white;
    }
    
  }

  //stoploss component
  #stoploss-component{
    background-color: #0F0F1A;

    #primary-div{
      background-color: #43434A;
    }

    p{
      color: white;
    }

    h3{
      color:white;
    }

    span{
      color: white;
    }

    .mdc-text-field.mdc-text-field--outlined.stoploss{
      border-bottom: 1px solid white;
      background-color: black;
      span{
        color: white;
      }

      .mdc-text-field__input{
        color:white;
      }
    }

    .mdc-switch__thumb{
      background-color: white;
    }

    .mdc-text-field-helper-text.mdc-text-field-helper-text--persistent{
      color: white;
    }
  }

  //preferences component
  #preferences{
    color: white;

    #dark-text{
      color: white;
    }

    #title-tools{
      background-color: #6E6E6E;
      color: white;
    }

    #title-color{
      background-color: #6E6E6E;
      color: white;
    }

    .mdc-chip__text{
      color: black;
    }

    .mdc-switch__thumb{
      background-color: white;
    }
  }

  //risk component
  #risk-component{
    background-color: #0F0F1A;
    h3{
      color: white;
    }

    p{
      color:white;
    }

    span{
      color:white;
    }

    #primary-div{
      background-color: #43434A;
    }
    
    .mdc-select__menu.mdc-menu.mdc-menu-surface.mdc-menu-surface--fullwidth.mdc-menu-surface--open.mdc-menu-surface--is-open-below{
      background-color: black;
    }

    .mdc-text-field-helper-text.mdc-text-field-helper-text--persistent{
      color: white;
    }
    .mdc-text-field.mdc-text-field--outlined.risk{
      border-bottom: 1px solid white;
      background-color: black;
      span{
        color: white;
      }

      .mdc-text-field__input{
        color:white;
      } 
    }

    .mdc-select__anchor{
      background-color: black;
      border-bottom: 1px solid white;
    }

    .mdc-text-field.mdc-text-field--outlined.entry{
      background-color: black;
      border-bottom: 1px solid white;

      .mdc-text-field__input{
        color: white;
      }
    }

    .mdc-text-field.mdc-text-field--outlined.exit{
      background-color: black;
      border-bottom: 1px solid white;

      .mdc-text-field__input{
        color: white;
      }
    }

    .mdc-text-field.mdc-text-field--outlined.riskDivisa.mdc-text-field--disabled{
      background-color: black; 
      border: 1px solid white;
      .mdc-text-field__input{
        color: white;
      }
    }
  }

  //help-center component
  #help-center{
    h3{
      color: white;
    }

    .mdc-select__anchor{
      background-color: black; 
      border-bottom: 1px solid white;
      color: white;
    }

    .mdc-select__menu.mdc-menu.mdc-menu-surface.mdc-menu-surface--fullwidth.mdc-menu-surface--open.mdc-menu-surface--is-open-below{
      background-color: black;
      color: white;
    }

    .mdc-select-helper-text{
      color: white;
    }
    
    .mdc-select__dropdown-icon-graphic{
      fill: white;
    }


    .mdc-select__selected-text{
      color: white;
    }

    .mdc-floating-label.mdc-floating-label--float-above{
      color: white;
    }
  }

  //broker simulator component
  #broker-simulator{
    h2{
      color: white;
    }
    p{
      color: white;
    }
    span{
      color: white;
    }

    h3{
      color: white;
    }
    svg{
      fill: white;
    }
    .mdc-select__anchor{
      background-color: black;
      border-bottom: 1px solid white;
    }
    .mdc-list{
      background-color: #202020;
      border: 2px solid rgb(65, 65, 65);
    }
    .mdc-list-item:hover{
      background-color: grey;
    }
    .mdc-text-field.mdc-text-field--outlined.input_amount_value{
      background-color: black;
      border-bottom: 1px solid white;
    }
    .mdc-text-field.mdc-text-field--outlined.input_price_value{
      background-color: black;
      border-bottom: 1px solid white;
    }
    .mdc-text-field__input{
      color: white;
    }
    .text-grey-uppercase{
      color:white;
    }
    .mdc-switch__thumb{
      background-color: white;
    }
    .mdc-text-field.mdc-text-field--outlined.input_amount_ifdone_stop_loss_value{
      background-color: black;
      border-bottom: 1px solid white;
    }
    .mdc-text-field.mdc-text-field--outlined.input_price_ifdone_stop_loss_value{
      background-color: black;
      border-bottom: 1px solid white;
    }
    .input-container-size{
      background-color: transparent;
    }
    .mdc-text-field.mdc-text-field--outlined.input_amount_oco_value{
      background-color: black;
      border-bottom: 1px solid white;
    }
    .mdc-text-field.mdc-text-field--outlined.input_price_oco_value{
      background-color: black;
      border-bottom: 1px solid white;
    }
    .mdc-text-field.mdc-text-field--outlined.input_amount_ifdone_take_profit_value{
      background-color: black;
      border-bottom: 1px solid white;
    }
    .mdc-text-field.mdc-text-field--outlined.input_price_ifdone_take_profit_value{
      background-color: black;
      border-bottom: 1px solid white;
    }
  }

  //directionality component
  #directionality{
    background-color: #0F0F1A;
    h3{
      color: white;
    }
    .mdc-data-table__row{
      background-color: black;
    }
    .mdc-data-table__cell.mdc-data-table__cell--numeric.one-mount{
      color: white;
    }
    .mdc-data-table__cell.mdc-data-table__cell--numeric.three-month{
      color: white;
    }
    .mdc-data-table__cell.mdc-data-table__cell--numeric.one-year{
      color: white;
    }
    .mdc-data-table__cell.options{
      color: white;
    }
    .sector-text{
      color: black;
    }
    .text-margin-bold{
      color: black;
    }
    .sector-text.text-bold{
      color: white;
    }
    .sector-text-rend{
      color: white;
      margin: 0;
      padding-left: 40px;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 24px;
    }
    .mdc-button.mdc-button--icon-leading.short.mdc-button--raised.mdc-ripple-upgraded{
      background-color: white;
    }
    .mdc-button__label{
      color: black;
    }
    .mdc-button.mdc-button--icon-leading.middle.mdc-ripple-upgraded{
      background-color: white;
    }
    .mdc-button.mdc-button--icon-leading.large.mdc-ripple-upgraded{
      background-color: white;
    }
    .mdc-tab-bar.directionality-toggle{
      background-color: #0f0f1a !important;
    }
    .mdc-tab__ripple.mdc-ripple-upgraded{
      background-color:#222934 ;      
    }
    .mdc-tab-indicator.mdc-tab-indicator--active{
      background-color: #00001a;
      color:rgb(255, 255, 255) ;
    }
    .mdc-tab__text-label{
      color:white !important;
    }
  }

  .o_button_color {
    @include button.ink-color(red);
    @include button.container-fill-color(white);
  }
}