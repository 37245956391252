$sidebar: 64px;
$open_sidebar: 315px;
$eurekers_color: #00add8;
$main_color: #151929;
$main_color_white: #ffffff;
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

ul li {
  list-style: none;
}


#content {
  background-color: var(--color-background);
  &.sidebar-open {
    margin-left: $open_sidebar - $sidebar;
  }
}

#content,
#sidebar-nav-container {
  height: calc(100vh);
}

.material-icons {
  &.md-18 {
    font-size: 18px;
  }
  &.md-24 {
    font-size: 24px;
  }
  &.md-32 {
    font-size: 32px;
    color: white;
  }
  &.md-36 {
    color: white;
    font-size: 36px;
  }
  &-two-tone,
  &.md-48 {
    font-size: 48px;
  }
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
  cursor: pointer;
}

.bm-burger-bars {
  background-color: #373a47;
}

.line-style {
  position: absolute;
  height: 20%;
  left: 0;
  right: 0;
}

.cross-style {
  position: absolute;
  top: 12px;
  right: 2px;
  cursor: pointer;
}

.widgets-container{
  cursor: default;
}

@import "dark.scss";
@import "../animations/transform.scss";